import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/views/index.vue'

const loginFlag = true

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      index: 1,
      title: '绿野星辰放心食材',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/category',
    name: 'CategoryNoparamView',
    component: () => import('@/views/ware/category.vue'),
    meta: {
      index: 2,
      title: '商品分类',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/ware/view',
    name: 'WareView',
    component: () => import('@/views/ware/view.vue'),
    meta: {
      index: 2,
      title: '商品详情',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/ware/search',
    name: 'WaresearchView',
    component: () => import('@/views/ware/search.vue'),
    meta: {
      index: 2,
      title: '商品搜索',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/ware/shoppingcart',
    name: 'ShoppingcartView',
    component: () => import('@/views/ware/shoppingcart.vue'),
    meta: {
      index: 2,
      title: '购物车',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/order/confirm',
    name: 'OrderConfirm',
    component: () => import('@/views/order/confirm.vue'),
    meta: {
      index: 3,
      title: '订单确认',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/order/mylist',
    name: 'OrderMylist',
    component: () => import('@/views/order/mylist.vue'),
    meta: {
      index: 3,
      title: '我的订单',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/order/mydetail',
    name: 'OrderMydetail',
    component: () => import('@/views/order/mydetail.vue'),
    meta: {
      index: 4,
      title: '订单详情',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/order/package',
    name: 'OrderPackage',
    component: () => import('@/views/order/package.vue'),
    meta: {
      index: 5,
      title: '订单包裹',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/order/succeed',
    name: 'SucceedView',
    component: () => import('@/views/order/succeed.vue'),
    meta: {
      index: 5,
      title: '订单包裹',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/customservice',
    name: 'customService',
    component: () => import('@/views/profile/customservice.vue'),
    meta: {
      index: 2,
      title: '客服中心',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/address/list',
    name: 'AddressList',
    component: () => import('@/views/profile/address.vue'),
    meta: {
      index: 4,
      title: '收货地址',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/address/oper',
    name: 'AddressOper',
    component: () => import('@/views/profile/addressmodify.vue'),
    meta: {
      index: 4,
      title: '地址修改',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/mine',
    name: 'mineView',
    component: () => import('@/views/profile/mine.vue'),
    meta: {
      index: 1,
      title: '个人中心',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/mine/balance',
    name: 'mineBalance',
    component: () => import('@/views/profile/balance.vue'),
    meta: {
      index: 2,
      title: '我的余额',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/mine/recharge',
    name: 'mineRecharge',
    component: () => import('@/views/profile/recharge.vue'),
    meta: {
      index: 3,
      title: '充值',
      requiresAuth: loginFlag,
    },
  },
  {
    path: '/sp',
    name: 'singlepage',
    component: () => import('@/views/singlepage.vue'),
    meta: {
      index: 2,
      title: '单页面',
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '授权登陆',
      requiresAuth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let obj = {
      top: 0,
      left: 0,
    }
    if (savedPosition) {
      obj.top = savedPosition.top
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(obj)
      }, 500)
    })
  },
})

export default router
